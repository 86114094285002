<template>
  <div>
    <Head @closeMenu="closeOpenMenu" />
    <div class="banner">
      <!-- <div class="forward"></div> -->
    </div>
    <router-view />
    <Menu ref="menu" />
    <Topreturn @closeMenu="closeOpenMenu"></Topreturn>
  </div>
</template>

<script>
import Head from "@/components/head.vue";
import Menu from "@/components/menu.vue";
import Topreturn from "@/components/Topreturn.vue";
import setScrollTop from "../../utils/setScrollTop.js";

export default {
  data() {
    return {};
  },
  components: {
    Head,
    Menu,
    Topreturn
  },
    watch: {
    $route(to, from) {
      setScrollTop('title');
    },
  },
  mounted(){
    setScrollTop('title');
  },
  methods: {
    closeOpenMenu(t) {
      this.$refs.menu.menuShow = true;
    },
  },
};
</script>

<style scoped>
/* banner图开始 */
.banner {
  width: 100%;
  height: 1080px;
  background-image: url("../../assets/image/contactUs/banner.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: relative;
}
.banner .forward {
  width: 164px;
  height: 44px;
  background-image: url("../../assets/image/aboutGroup/index/BnnerContext.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  right: 58px;
  bottom: 26px;
}
/* banner图结束 */
</style>